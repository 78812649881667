import React from "react";
import { Cell, Pie, PieChart } from "recharts";
import "./Achievement.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";

export type AchievementMode = 'compact' | 'verbose';

export interface AchievementProps {
    mode: AchievementMode;
    loading: boolean;
    numerator: number;
    bronze: number;
    silver: number;
    gold: number;
    icon: IconProp;
    title?: string;
    description?: string;
}

export default function (props: AchievementProps) {
    const bronzeColor: string = '#cd7f32';
    const silverColor: string = '#bcc6cc';
    const goldColor: string = '#ffd700';
    const onColor: string = '#43963d';
    const offColor: string = '#dddddd';

    let displayNumerator: number = props.loading ? 0 : Math.min(props.gold, props.numerator!);
    let displayDenominator = props.bronze;
    let badgeColor = '';

    if (displayNumerator === props.gold) {
        displayDenominator = props.gold;
        badgeColor = goldColor;
    } else if (displayNumerator >= props.silver) {
        displayDenominator = props.gold;
        badgeColor = silverColor;
    } else if (displayNumerator >= props.bronze) {
        displayDenominator = props.silver;
        badgeColor = bronzeColor
    }

    let scaledNumerator = displayNumerator;
    let scaledDenominator = displayDenominator;
    if (scaledDenominator > 10) {
        let scale = scaledDenominator / 10;
        scaledDenominator /= scale;
        scaledNumerator = scaledNumerator === 0 ? 0 : Math.max(Math.floor(scaledNumerator / scale), 1);
    }

    const data = [];
    for (let x = 0; x < scaledDenominator; x++) {
        data.push({name: 'Group ' + x, value: 1})
    }

    return <div className="achievement">
        <div>
            <div className="achievement-chart-wrapper">
                <div className="achievement-chart">
                    <PieChart width={60} height={60}>
                        <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            innerRadius={22}
                            outerRadius={30}
                            paddingAngle={5}
                            dataKey="value"
                            isAnimationActive={!props.loading && scaledNumerator > 0}
                            animationBegin={0}
                            animationDuration={500}
                            startAngle={450}
                            endAngle={90}
                        >
                            {data.map((entry, index) => {
                                let color = offColor;
                                if (index < scaledNumerator) {
                                    if (displayNumerator === props.gold) {
                                        color = goldColor;
                                    } else {
                                        color = onColor;
                                    }
                                }
                                return <Cell key={`cell-${index}`} fill={color}/>;
                            })}
                        </Pie>
                    </PieChart>
                </div>
                <div className="achievement-chart-icon" style={{backgroundColor: badgeColor}}>
                    <FontAwesomeIcon icon={props.icon}/>
                </div>
            </div>
            <div className="achievement-ratio-wrapper">
                {props.loading ?
                    <div className="achievement-ratio-spinner">
                        <FontAwesomeIcon icon={faRefresh} spin={true}/>
                    </div>
                    : <div>
                        <div className="achievement-ratio">{displayNumerator + '/' + displayDenominator}</div>
                    </div>
                }
            </div>
        </div>
        {props.mode === 'verbose' &&
        <div>
            <div className="achievement-info">
                <div className="achievement-title">{props.title}</div>
                <div className="achievement-description">{props.description}</div>
            </div>
            <div className="achievement-progress-wrapper">
                <div className="achievement-progress-bar-wrapper">
                    <div className="achievement-progress-bar" style={{width: (displayNumerator * 100 / props.gold) + '%'}}/>
                </div>
                <div className="achievement-progress-badges">
                    <div className="achievement-progress-badge" style={{backgroundColor: bronzeColor, left: `${(props.bronze * 100 / props.gold)}%`}}/>
                    <div className="achievement-progress-badge" style={{backgroundColor: silverColor, left: `${(props.silver * 100 / props.gold)}%`}}/>
                    <div className="achievement-progress-badge" style={{backgroundColor: goldColor, left: '100%'}}/>
                </div>
            </div>
        </div>
        }
    </div>;
}