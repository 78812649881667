import React, { useEffect } from 'react';
import { Layout } from '@careevolution/mydatahelps-ui';
import { parseISO } from 'date-fns';
import { getDayKey, LogEntryEdit } from '@careevolution/symptom-shark';

export interface DayViewProps {
    viewName?: string;
    onVisit?(day: string): void;
}

export default function (props: DayViewProps) {
    let date = new Date();
    let search = new URLSearchParams(window.location.search);
    if (search.get("date")) {
        date = parseISO(search.get("date")!)
    }

    useEffect(() => {
        props.onVisit?.(getDayKey(date));
    }, []);
    document.body.classList.remove('symptom-shark');

    return (
        <Layout bodyBackgroundColor="#fff">
            <LogEntryEdit date={date} promptForReviewAfterDays={7}/>
        </Layout>
    );
}