import React from 'react';
import ReactDOM from 'react-dom';
import MyDataHelps from '@careevolution/mydatahelps-js';
import '@careevolution/symptom-shark'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './views/Home';
import SurveyTasks from './views/SurveyTasks';
import { CalendarView, DeviceActivityView, getDayKey, ReportsView, SetupStep, SymptomDetailView } from '@careevolution/symptom-shark';
import './nexus.css'
import DayView from './views/DayView';
import MyAchievements from './views/MyAchievements';

if (window.location.hostname === 'localhost' && !!process.env.REACT_APP_PARTICIPANT_ACCESS_TOKEN) {
    MyDataHelps.setParticipantAccessToken({'access_token': process.env.REACT_APP_PARTICIPANT_ACCESS_TOKEN!, 'expires_in': 21600, 'token_type': 'Bearer'}, 'https://mydatahelps.org/');
}

const selectDay = (date: Date): void => {
    MyDataHelps.openApplication(new URL('/day/?date=' + getDayKey(date), document.location as any).href);
};

const selectSymptom = (s: String, y: Number, m: Number): void => {
    MyDataHelps.openApplication(new URL('/symptom/?month=' + m + '&year=' + y + '&symptom=' + s, document.location as any).href, {modal: true});
};

if (document.getElementById('root')) {
    ReactDOM.render(
        <React.StrictMode>
            <Router>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/home/" element={<Home/>}/>
                    <Route path="/surveytasks/" element={<SurveyTasks/>}/>
                    <Route path="/myachievements/" element={<MyAchievements/>}/>
                    <Route path="/calendar/" element={<CalendarView
                        onDaySelected={(d) => selectDay(d)}
                        onSymptomSelected={(s, y, m) => selectSymptom(s, y, m)}/>}/>
                    <Route path="/day/" element={<DayView/>}/>
                    <Route path="/symptom/" element={<SymptomDetailView/>}/>
                    <Route path="/reports/" element={<ReportsView/>}/>
                    <Route path="/deviceactivity/" element={<DeviceActivityView onDaySelected={(d) => selectDay(d)}/>}/>
                </Routes>
            </Router>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

function router(rootClassName: string) {
    switch (rootClassName) {
        case 'symptom-setup':
            return <SetupStep
                title="What symptoms do you want to log?"
                description="You can choose what symptoms you want to log.  You can also come back and edit this list later."
                doneButtonText="Next: Treatments"
                initialSetup={true}
                requireItems={true}
                inputPlaceholder="Symptom name, e.g. Headache"
                concept="Symptoms"
                stepIdentifier="Symptoms"/>
        case 'treatment-setup':
            return <SetupStep
                title="What treatments do you want to log?"
                description='Treatments can be anything you do to help reduce your symptoms.  For best results, focus on "as needed" treatments and not daily medications or things that you always do each day.'
                doneButtonText="Next: Reminders"
                initialSetup={true}
                requireItems={false}
                inputPlaceholder="Treatment name, e.g. Tylenol"
                stepIdentifier="Treatments"
                concept="Treatments"/>
        case 'symptom-edit':
            return <SetupStep
                title="What symptoms do you want to log?"
                description="Select 'Options' to change symptom color, enable logging symptom severity or to mute a symptom."
                doneButtonText="Save Symptoms"
                initialSetup={false}
                requireItems={true}
                inputPlaceholder="Symptom name, e.g. Headache"
                concept="Symptoms"/>
        case 'treatment-edit':
            return <SetupStep
                title="What treatments do you want to log?"
                description='Select "Options" to change treatment color or to mute a treatment.'
                doneButtonText="Save Treatments"
                initialSetup={false}
                requireItems={false}
                inputPlaceholder="Treatment name, e.g. Tylenol"
                concept="Treatments"/>
    }
    return <div/>;
}

let root = document.getElementById('remote-root');
if (root) {
    ReactDOM.render(router(root.className), root);
}