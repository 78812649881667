import React, { useState } from "react";
import "./Achievements.css";
import Achievement from "./Achievement";
import { faCalendarDays, faListCheck, faSquarePollHorizontal, faUserDoctor } from "@fortawesome/pro-solid-svg-icons";
import { faWatchFitness } from "@fortawesome/pro-light-svg-icons";
import MyDataHelps, { ParticipantInfo } from "@careevolution/mydatahelps-js";
import { useInitializeView } from "../helpers/Initialization";

const moment = require('moment');

export type AchievementsMode = 'compact' | 'verbose';

export interface AchievementsProps {
    mode: AchievementsMode;
}

export default function (props: AchievementsProps) {
    let [loading, setLoading] = useState<boolean>(true);

    let [shareEhrCount, setShareEhrCount] = useState<number>();
    let [symptomDiaryCount, setSymptomDiaryCount] = useState<number>();
    let [surveyCount, setSurveyCount] = useState<number>();
    let [weeklyCheckInCount, setWeeklyCheckInCount] = useState<number>();
    let [deviceDataCount, setDeviceDataCount] = useState<number>();
    let [requiredSurveyCount, setRequiredSurveyCount] = useState<number>(10);

    const getEhrCount = (participantInfo: ParticipantInfo, badgeCustomFieldName: string): number => {
        if (participantInfo.customFields.hasOwnProperty(badgeCustomFieldName) && participantInfo.customFields[badgeCustomFieldName] === 'Gold') {
            return 1;
        }
        return 0;
    };

    const getCount = (participantInfo: ParticipantInfo, countCustomFieldName: string): number => {
        if (participantInfo.customFields.hasOwnProperty(countCustomFieldName)) {
            let count = participantInfo.customFields[countCustomFieldName];
            return !!count ? parseInt(count) : 0;
        }
        return 0;
    };

    const loadParticipantInfo = (): Promise<void> => {
        return MyDataHelps.getParticipantInfo().then((participantInfo) => {
            let today = moment().startOf('day');
            let enrollmentDate = moment(participantInfo.enrollmentDate).startOf('day');
            let oneYearAfterEnrollment = moment(enrollmentDate).add(1, 'year');
            let twoYearsAfterEnrollment = moment(enrollmentDate).add(2, 'year');
            let threeYearsAfterEnrollment = moment(enrollmentDate).add(3, 'year');
            if (today.isBetween(enrollmentDate, oneYearAfterEnrollment, 'day', '[)')) {
                setShareEhrCount(getEhrCount(participantInfo, 'Achievement_ShareEHR_Year1_Badge'));
                setSymptomDiaryCount(getCount(participantInfo, 'Achievement_SymptomDiary_Year1_Count'));
                setSurveyCount(getCount(participantInfo, 'Achievement_Survey_Year1_Count'));
                setWeeklyCheckInCount(getCount(participantInfo, 'Achievement_WeeklyCheckIn_Year1_Count'));
                setDeviceDataCount(getCount(participantInfo, 'Achievement_DeviceData_Year1_Count'));
                setRequiredSurveyCount(23);
            } else if (today.isBetween(oneYearAfterEnrollment, twoYearsAfterEnrollment, 'day', '[)')) {
                setShareEhrCount(getEhrCount(participantInfo, 'Achievement_ShareEHR_Year2_Badge'));
                setSymptomDiaryCount(getCount(participantInfo, 'Achievement_SymptomDiary_Year2_Count'));
                setSurveyCount(getCount(participantInfo, 'Achievement_Survey_Year2_Count'));
                setWeeklyCheckInCount(getCount(participantInfo, 'Achievement_WeeklyCheckIn_Year2_Count'));
                setDeviceDataCount(getCount(participantInfo, 'Achievement_DeviceData_Year2_Count'));
                setRequiredSurveyCount(16);
            } else if (today.isBetween(twoYearsAfterEnrollment, threeYearsAfterEnrollment, 'day', '[)')) {
                setShareEhrCount(getEhrCount(participantInfo, 'Achievement_ShareEHR_Year3_Badge'));
                setSymptomDiaryCount(getCount(participantInfo, 'Achievement_SymptomDiary_Year3_Count'));
                setSurveyCount(getCount(participantInfo, 'Achievement_Survey_Year3_Count'));
                setWeeklyCheckInCount(getCount(participantInfo, 'Achievement_WeeklyCheckIn_Year3_Count'));
                setDeviceDataCount(getCount(participantInfo, 'Achievement_DeviceData_Year3_Count'));
                setRequiredSurveyCount(13);
            }
        });
    };

    useInitializeView(() => {
        setLoading(true);
        loadParticipantInfo().then(() => {
            setLoading(false);
        });
    });

    return <div className={props.mode === 'compact' ? 'achievements-compact' : 'achievements-verbose'}>
        <Achievement mode={props.mode} loading={loading} numerator={shareEhrCount!} bronze={1} silver={1} gold={1} icon={faUserDoctor} title="Connect your medical records" description="Connect your medical records by scrolling down on your MyDataHelps Dashboard and press &quot;Connect Provider&quot;."/>
        <Achievement mode={props.mode} loading={loading} numerator={symptomDiaryCount!} bronze={6} silver={180} gold={360} icon={faCalendarDays} title="Log your symptoms" description="Enter daily symptoms logs to earn this badge."/>
        <Achievement mode={props.mode} loading={loading} numerator={surveyCount!} bronze={requiredSurveyCount} silver={requiredSurveyCount} gold={requiredSurveyCount} icon={faSquarePollHorizontal} title="Complete your surveys" description="Complete surveys on your task list to earn this badge."/>
        <Achievement mode={props.mode} loading={loading} numerator={weeklyCheckInCount!} bronze={6} silver={25} gold={50} icon={faListCheck} title="Complete weekly check-ins" description="Complete weekly check-in surveys to earn this badge."/>
        <Achievement mode={props.mode} loading={loading} numerator={deviceDataCount!} bronze={10} silver={180} gold={360} icon={faWatchFitness} title="Connect your smart device" description="Connect your smart device (e.g. Apple Watch, Fitbit) to MyDataHelps, if you own one, by selecting your device and logging in."/>
    </div>;
}