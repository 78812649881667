import React from 'react'
import './NavigationBar.css';
import MyDataHelps from "@careevolution/mydatahelps-js";

export interface NavigationBarProps {
    onBack?: () => void;
}

export default function (props: NavigationBarProps) {

    const onBackToDashboard = () => {
        if (props.onBack) {
            props.onBack()
        } else {
            MyDataHelps.dismiss();
        }
    }

    return (
        <div className="navigation-bar">
            <a className="navigation-bar-link" onClick={() => onBackToDashboard()}>&lt; Back</a>
        </div>
    )
}