import React from 'react'
import { Layout } from '@careevolution/mydatahelps-ui'
import Achievements from '../components/Achievements';
import './MyAchievements.css';
import NavigationBar from '../components/NavigationBar';

export default function () {
    const bronzeColor: string = '#cd7f32';
    const silverColor: string = '#bcc6cc';
    const goldColor: string = '#ffd700';

    return (
        <Layout bodyBackgroundColor="#fff">
            <div className="my-achievements">
                <NavigationBar/>
                <div className="my-achievements-title">My Compensation Badges</div>
                <div className="my-achievements-description">Earn each of the 5 badges by completing study tasks! Earn at least 2 bronze badges to be entered into the annual compensation raffle. Any additional badges you earn will grant you additional entries.</div>
                <div className="my-achievements-badges">
                    <div className="my-achievements-badge" style={{backgroundColor: bronzeColor}}>Bronze</div>
                    <div className="my-achievements-badge" style={{backgroundColor: silverColor}}>Silver</div>
                    <div className="my-achievements-badge" style={{backgroundColor: goldColor}}>Gold</div>
                </div>
                <Achievements mode='verbose'/>
            </div>
        </Layout>
    )
}