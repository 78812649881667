import React, { useState } from 'react'
import { Action, Card, CardTitle, ConnectEhr, ConnectFitbit, Layout, PlatformSpecificContent, ProjectHeader, ProjectSupport, StatusBarBackground, SurveyTaskList, TextBlock } from '@careevolution/mydatahelps-ui'
import MyDataHelps, { ParticipantInfo } from '@careevolution/mydatahelps-js'
import { add } from 'date-fns';
import { convertToSymptomSharkParticipantInfo, DailyLogEntry, getDayKey, LogEntry, symptomSharkData, SymptomSharkParticipantInfo } from '@careevolution/symptom-shark';
import { faBell, faChevronRight, faCog } from '@fortawesome/free-solid-svg-icons';
import Achievements from '../components/Achievements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Home.css'
import { useInitializeView } from '../helpers/Initialization';

export default function () {
    let tasksViewUrl = new URL('/surveytasks/', document.location as any).href;
    let ehrConnectApplicationUrl = 'https://viewlibrary.careevolutionapps.com/connectehr?presentation=Push';

    const [loading, setLoading] = useState<boolean>(true);
    const [participantInfo, setParticipantInfo] = useState<ParticipantInfo | undefined>(undefined);
    const [symptomLogEntry, setSymptomLogEntry] = useState<DailyLogEntry | undefined>(undefined);

    let currentDate = new Date();
    currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0);

    useInitializeView(function () {
        MyDataHelps.getParticipantInfo().then(function (participantInfo) {
            symptomSharkData.getDailyLogEntries(getDayKey(add(currentDate, {days: -1}))).then(function (logEntries) {
                setParticipantInfo(participantInfo);
                if (logEntries[getDayKey(currentDate)]) {
                    setSymptomLogEntry(logEntries[getDayKey(currentDate)]);
                } else {
                    setSymptomLogEntry(undefined);
                }
                setParticipantInfo(participantInfo);
                setLoading(false);
            });
        });
    });

    const selectDay = (date: Date): void => {
        MyDataHelps.openApplication(new URL('/day/?date=' + getDayKey(date), document.location as any).href);
    };

    const viewAllTasks = (): void => {
        MyDataHelps.openApplication(tasksViewUrl);
    };

    const onAchievementsClicked = (): void => {
        MyDataHelps.openApplication(new URL('/myachievements', document.location as any).href, {modal: true});
    };

    let symptomSharkParticipantInfo: SymptomSharkParticipantInfo | null = null;
    let startedSymptomTracker = false;
    if (participantInfo) {
        symptomSharkParticipantInfo = convertToSymptomSharkParticipantInfo(participantInfo);
        startedSymptomTracker = symptomSharkParticipantInfo.symptoms.length > 0;
    }

    return (
        <Layout bodyBackgroundColor="#f5f5f5">
            <StatusBarBackground/>
            <ProjectHeader/>
            <PlatformSpecificContent platforms={['Web']}>
                <Card>
                    <TextBlock>
                        <p><strong>Next: Download the App</strong></p>
                        <div className="card-subtitle" style={{marginBottom: '16px'}}>
                            Downloading the MyDataHelps app makes it even easer to participate.
                        </div>
                        <a target="_blank" className="app-download-link" href="https://play.google.com/store/apps/details?id=com.careevolution.mydatahelps&hl=en_US&gl=US">
                            <img style={{height: '44px'}} src="/images/google-play-download.svg" alt="Download on the Google Play Store"/>
                        </a>
                        <a target="_blank" className="app-download-link" href="https://apps.apple.com/us/app/mydatahelps/id1286789190">
                            <img style={{height: '44px'}} src="/images/app-store-download.svg" alt="Download on the Apple App Store"/>
                        </a>
                    </TextBlock>
                </Card>
            </PlatformSpecificContent>
            {!loading && !startedSymptomTracker &&
			<Card>
				<Action
					title="Start your symptom log"
					subtitle="Choose the symptoms and treatments you want to track"
					onClick={() => MyDataHelps.startSurvey('SetupSymptomTracker')}/>
			</Card>
            }
            {!loading && startedSymptomTracker &&
			<LogEntry date={currentDate} participantInfo={symptomSharkParticipantInfo!} onDaySelected={(d) => selectDay(d)} logEntry={symptomLogEntry}/>
            }
            <Card>
                <SurveyTaskList
                    status="incomplete"
                    limit={3}
                    title="Incomplete Tasks"
                    onDetailLinkClick={() => viewAllTasks()}
                />
            </Card>
            <Card>
                <div onClick={() => onAchievementsClicked()}>
                    <div className="home-achievements-header">My Compensation Badges <FontAwesomeIcon icon={faChevronRight}/></div>
                    <Achievements mode='compact'/>
                </div>
            </Card>
            <Card>
                <ConnectFitbit title="Fitbit"/>
            </Card>
            <Card>
                <ConnectEhr applicationUrl={ehrConnectApplicationUrl}/>
            </Card>
            {startedSymptomTracker && !!symptomSharkParticipantInfo &&
			<Card className="manage-symptom-log">
				<CardTitle title="Manage Symptom Log"/>
				<Action indicatorIcon={faCog} title="Edit Symptoms" subtitle={symptomSharkParticipantInfo.symptoms.length + ' Active Symptoms'} onClick={() => MyDataHelps.startSurvey('UpdateSymptoms')}/>
				<Action indicatorIcon={faCog} title="Edit Treatments" subtitle={symptomSharkParticipantInfo.treatments.length + ' Active Treatments'} onClick={() => MyDataHelps.startSurvey('UpdateTreatments')}/>
				<Action
					className="action-with-icon"
					title="Edit Reminders"
					onClick={() => MyDataHelps.startSurvey('UpdateReminders')}
					indicatorIcon={faBell}
					subtitle={symptomSharkParticipantInfo.reminder1Enabled ? 'Enabled' : 'Disabled'}/>
			</Card>
            }
            <Card>
                <ProjectSupport/>
            </Card>
        </Layout>
    )
}